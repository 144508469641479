<template>
    <van-dialog v-model="showTemp" class-name="coupon-add-dialog" :show-confirm-button="false" :close-on-click-overlay="true">
        <div class="coupon-add--header"></div>
        <div class="coupon-add--content fx-center-middle">
            <van-field v-model="input" class="input" ref="field" :maxlength="32" clearable placeholder="请输入兑换码">
                <template #button>
                    <div class="fx-center">
                        <van-button size="mini" class="btn-paste" @click="paste">
                            <template #icon>
                                <van-icon :name="require('./static/paste.png')" style="margin-right: 8px;margin-top: 2px;"></van-icon>
                            </template>
                            <span>粘贴</span>
                        </van-button>
                    </div>
                </template>
            </van-field>
        </div>
        <div class="coupon-add--footer fx-center-middle">
            <template v-if="showCancel">
                <van-button type="primary" round @click="cancel">取消</van-button>
                <van-button type="primary" round @click="confirm">查询绑定</van-button>
            </template>
            <template v-else>
                <van-button type="primary" block round @click="confirm">查询绑定</van-button>
            </template>
        </div>
    </van-dialog>
</template>

<script>
export default {
    model: {
        prop: 'show',
        event: 'update'
    },
    props: {
        show: Boolean,
        showCancel: Boolean,
    },
    data() {
        return {
            input: '',
        }
    },
    computed: {
        'showTemp': {
            get() {
                return this.show;
            },
            set(val) {
                this.input = "";
                this.$emit('update', val);
            }
        }
    },
    methods: {
        paste() {
            navigator.clipboard.readText().then((text) => {
                this.input = text;
            });
        },
        confirm() {
            if (this.validateInput()) {
                this.$emit('confirm', this.input);
            }
        },
        validateInput() {
            if (!this.input) {
                this.$toast('不能为空');
                return false;
            }
            return true;
        }
    }
}
</script>

<style lang="scss" scoped>
.coupon-add-dialog {
	border-radius: 6px;
	.coupon-add--header {
		// background-color: $primary-color;
		height: 30px;
	}
	.coupon-add--content {
		padding: 30px 25px;
		.input {
			border: 1px solid $primary-color;
			border-radius: 25px;
		}
		.btn-paste {
			padding-left: 15px;
			border-top: 0px;
			border-right: 0px;
			border-bottom: 0px;
		}
	}

	.coupon-add--footer {
		padding: 20px 30px;
		padding-top: 0;
	}
}
</style>
