import axios from '../axios';

export function getCouponList(data) {
    const url = "/member/coupon/list";
    return axios.request({
        url,
        method: 'get',
        data
    })
}

export function queryCoupon(data) {
    const url = "/member/coupon/query";
    return axios.request({
        url,
        method: 'get',
        data
    }) 
}

export function bindCoupon(data) {
    const url = "/member/coupon/bind";
    return axios.request({
        url,
        method: 'post',
        data
    }) 
}

export function queryCouponByOrder(data) {
    const url = "/order/coupon/list";
    return axios.request({
        url,
        method: 'get',
        data
    })
}
/**
 * 
 * @param {使用优惠券} data 
 * @returns 
 */
export function useCoupon(data) {
    const url = "/order/coupon/use";
    return axios.request({
        url,
        method: 'post',
        data
    })
}
/**
 * 
 * @param {取消使用优惠券} data 
 * @returns 
 */
export function cancelUseCoupon(data) {
    const url = "/order/coupon/cancelUse";
    return axios.request({
        url,
        method: 'post',
        data
    })
}


