<template>
    <div class="user_card-item--wrap fx-column" @click="toggle">
        <!-- <van-image :src="require('@/assets/images/user/user_card_coupon.png')" width="25%" fit="cover"></van-image> -->
        <div class="fx-row">
            <div v-if="invalid" class="user_card-icon" :style="`background-image: url('${iconsDisabledMap.get(card.type)}'); width: 24%`"></div>
            <div v-else class="user_card-icon" :style="`background-image: url('${iconsMap.get(card.type)}'); width: 24%`"></div>
            <div class="user_card-content fx-fill">
                <div class="user_card-content--header fx-row fx-between fx-center">
                    <div>
                        <div class="user_card-name fx-center fx-row">
                            <span>{{cardName}}</span>
                            <van-icon v-if="info" name="info-o" style="margin-left: 5px;" @click.stop="$emit('click-info', card, card.typedesc)"></van-icon>
                        </div>
                        <div class="user_card-key fc-tips">{{card.cardno}}</div>
                    </div>
                    <div style="padding: 0 15px;">
                        <div v-if="!invalid">
                            <van-button v-if="!isCheck" :color="useColor" size="mini" style="padding: 14px 15px;" @click="toUse">去使用</van-button>
                            <div v-else>
                                <van-icon v-show="!card.checked" :name="require('./static/un-check.png')" size="16"></van-icon>
                                <van-icon v-show="card.checked" :name="require('./static/checked.png')" size="16"></van-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user_card-content--footer">
                    <span class="fc-tips">{{validityDate}}</span>
                </div>
            </div>
        </div>
        <div v-if="invalid" class="disabled fx-row fx-center">
            <van-icon :name="require('./static/info.png')" style="margin-right: 5px;"></van-icon>
            <span class="fc-status">不可用原因：{{unusedReason}}</span>
        </div>
    </div>
</template>

<script>
import { formatIntToMoney } from '@/libs/common-utils/common-utils';
export default {
    props: {
        card: Object,
        disabled: Boolean,
        isCheck: Boolean,
        info: {
            type: Boolean,
            default() {
                return true;
            }
        }
    },
    data() {
        return {
            iconsMap: new Map([
                ['A', require('./static/user_card_coupon.png')],
                ['D', require('./static/user_card_exchange.png')],
                ['V', require('./static/user_card_vip.png')],
            ]),
            iconsDisabledMap: new Map([
                ['A', require('./static/coupon_disabled.png')],
                ['D', require('./static/exchange_disabled.png')],
                ['V', require('./static/vip_disabled.png')],
            ])
        }
    },
    computed: {
        cardName() {
            if (this.card.typedesc) return this.card.typedesc;
            if (this.card.amount) return `余额：${formatIntToMoney(this.card.balance)}`;
            return '';
        },
        useColor() {
            if (this.card.type === 'A') return this.$config.style.primaryColor;
            if (this.card.type === 'D') return '#ED4133';
            return this.$config.style.primaryColor;
        },
        validityDate() {
            if (this.card.startdate && this.card.enddate) return `有效期：${this.card.startdate}至${this.card.enddate}`;
            else return `有效期至：${this.card.enddate}`
        },
        invalid() {
            if (this.disabled || this.card.status == 'L') {
                return true;
            }
            return false;
        },
        unusedReason() {
            if (this.card.unusedReason) return this.card.unusedReason;
            if (this.card.status == 'L') return '该卡券未激活或已冻结';
            return '';
        }
    },
    methods: {
        toUse() {
            this.$goPage("index");
        },
        toggle() {
            if (!this.disabled) {
                this.$set(this.card, 'checked', !!!this.card['checked']);
                this.$emit('update', this.card);
                this.$forceUpdate();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.user_card-item--wrap {
	margin-bottom: 8px;
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0px 2px 3px #bb96c1;
	.user_card-icon {
		background-size: 100% 100%;
		background-position: center;
	}
	.user_card-content {
		margin-left: 4px;
		.user_card-content--header {
			padding: 10px 0;
			padding-left: 6px;
			border-bottom: 1px solid $border-color;
			.user_card-name {
				font-size: 14px;
				font-weight: 700;
				height: 28px;
				line-height: 28px;
			}
		}
		.user_card-content--footer {
			font-size: 0.8em !important;
			padding: 6px 0;
			padding-left: 6px;
		}
	}

	.disabled {
		padding: 4px 10px;
	}
}
</style>