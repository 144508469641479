<template>
    <form name="aliPayForm" class="hidden" method="GET" :action="formAction">
        <input type="text" name="app_id" v-model="formData.app_id" />
        <input type="text" name="biz_content" v-model="formData.biz_content" />
        <input type="text" name="charset" v-model="formData.charset" />
        <input type="text" name="format" v-model="formData.format" />
        <input type="text" name="method" v-model="formData.method" />
        <input type="text" name="notify_url" v-model="formData.notify_url" />
        <input type="text" name="out_trade_no" v-model="formData.out_trade_no" />
        <input type="text" name="product_code" v-model="formData.product_code" />
        <input type="text" name="return_url" v-model="formData.return_url" />
        <input type="text" name="sign" v-model="formData.sign" />
        <input type="text" name="sign_type" v-model="formData.sign_type" />
        <input type="text" name="subject" v-model="formData.subject" />
        <input type="text" name="time_expire" v-model="formData.time_expire" />
        <input type="text" name="timestamp" v-model="formData.timestamp" />
        <input type="text" name="total_amount" v-model="formData.total_amount" />
        <input type="text" name="version" v-model="formData.version" />
    </form>
</template>

<script>
export default {
    props: {
        formData: Object,
        formAction: String,
    }
}
</script>

<style>
.hidden {
    display: none;
}
</style>