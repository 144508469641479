import axios from "../axios";

export function getOrder(data) {
    const url = `/movie/order${data ? `/${data}` : ''}`;
    return axios.request({
        url,
        method: 'get',
    })
}

export function doOrderPay(data) {
    const url = `/order/selectPaymethod`;
    return axios.request({
        url,
        method: 'get',
        data
    })
}


export function getOrderList(data) {
    const url = `/movie/order/list`;
    return axios.request({
        url,
        method: 'get',
        data
    })
}

export function cancelOrder(data) {
    const url = '/order/cancel';
    return axios.request({
        url,
        method: 'get',
        data
    })
}

export function deleteOrder(data) {
    const url = '/order/delete';
    return axios.request({
        url,
        method: 'get',
        data: {
            orderId: data
        }
    })
}


export function saveApplyRefund(data) {
    const url = '/order/applyRefundSave';
    return axios.request({
        url,
        method: 'post',
        data
    })
}

export function getApplyRefund(data) {
    const url = `/order/applyRefund`;
    return axios.request({
        url,
        method: 'get',
        data: {
            orderId: data
        }
    })
}


export function addOrderFood(data) {
    const url = `/movie/order/addBmh`;
    return axios.request({
        url,
        method: 'get',
        data
    })
}


export function getFoodOrderList(data = {}) {
    const url = `/bmh/order/list`;
    return axios.request({
        url,
        method: 'get',
        data
    })
}


export function getFoodOrder(data) {
    const url = `/bmh/order${data ? `/${data}` : ''}`;
    return axios.request({
        url,
        method: 'get'
    })
}
