<template>
    <div class="component-wrap fx-column" style="height: 100%;">
        <div class="fx-fill" style="position: relative;">
            <div class="fill-box">
                <van-tabs :color="$config.style.primaryColor" class="fx-column">
                    <div style="padding: 7px;">
                        <van-button block @click="showAdd = true">
                            <template #icon>
                                <van-icon name="add-o" :color="$config.style.primaryColor"></van-icon>
                            </template>
                            <span>添加卡券</span>
                        </van-button>
                    </div>
                    <van-tab :title="showCoupon ? '优惠券' : '兑换券'">
                        <div class="movie_tab-wrap fill-box">
                            <user-card v-for="item, index in couponListTemp" :key="index" :card="item" is-check @update="updateCouponList"></user-card>
                            <template v-if="unUsedCouponListTemp.length > 0">
                                <div style="padding: 5px 0;">不可使用优惠券</div>
                                <user-card v-for="item in unUsedCouponListTemp" :key="item.cardno" :card="item" disabled></user-card>
                            </template>
                        </div>
                    </van-tab>

                    <van-tab v-if="showCoupon" title="兑换券">
                        <div class="movie_tab-wrap fill-box">
                            <user-card v-for="item in exchangeCardListTemp" :key="item.cardno" :card="item" is-check @update="updateExchangeCardList"></user-card>
                            <template v-if="unUsedExchangeCardListTemp.length > 0">
                                <div style="padding: 5px 0;">不可使用优惠券</div>
                                <user-card v-for="item in unUsedExchangeCardListTemp" :key="item.cardno" :card="item" disabled></user-card>
                            </template>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="coupon-actions">
            <div class="coupon_confirm-btn-wrap fx-row fx-between fx-center">
                <div class="coupon_confirm-tips">已选中{{selectedLength}}张卡券</div>
                <div class="coupon_confirm fx-center" @click="doSelectCoupon">确定</div>
            </div>
        </div>
        <div class="utils-wrap">
            <user-card-dialog v-model="showAdd" @confirm="confirmAdd"></user-card-dialog>
        </div>
    </div>
</template>
<script>
import UserCard from '@/components/user/user-card';
import UserCardDialog from '@/components/user/user-card-dialog';
import { mapGetters } from 'vuex';
export default {
    model: {
        prop: 'selected',
        event: 'update'
    },
    props: {
        selected: Array,
        couponList: Array,
        unUsedCouponList: Array,
        exchangeCardList: Array,
        unUsedExchangeCardList: Array,
        maxSelected: Number,
    },
    data() {
        return {
            showAdd: false,
            couponListTemp: [],
            unUsedCouponListTemp: [],
            exchangeCardListTemp: [],
            unUsedExchangeCardListTemp: [],
        }
    },
    computed: {
        ...mapGetters([
            'appKey'
        ]),
        showCoupon() {
            // return true;
            return this.appKey != 'ykdyp134' ? true : false;
        },
        selectedLength() {
            if (this.selected && this.selected.length > 0) return this.selected.length;
            return 0;
        }
    },
    watch: {
        selected: {
            handler() {
                this.couponListTemp = this.generateTemp(this.couponList);
                this.exchangeCardListTemp = this.generateTemp(this.exchangeCardList);
            }
        },
        couponList: {
            immediate: true,
            handler() {
                this.couponListTemp = this.generateTemp(this.couponList);
            }
        },
        unUsedCouponList: {
            immediate: true,
            handler() {
                this.unUsedCouponListTemp = this.generateTemp(this.unUsedCouponList);
            }
        },
        exchangeCardList: {
            immediate: true,
            handler() {
                this.exchangeCardListTemp = this.generateTemp(this.exchangeCardList);
            }
        },
        unUsedExchangeCardList: {
            immediate: true,
            handler() {
                this.unUsedExchangeCardListTemp = this.generateTemp(this.unUsedExchangeCardList);
            }
        }
    },
    methods: {
        confirmAdd(val) {
            let loading = this.$toast.loading('绑定中');
            let params = {
                cardpass: val,
                showtype: 'Y'
            }
            this.$service.bindCoupon(params).then((res) => {
                let type = res.type;
                loading.clear();
                this.showAdd = false;
                this.$emit('refresh');
            });
        },
        doSelectCoupon() {
            this.$emit('confirm', JSON.parse(JSON.stringify(this.couponListTemp)));
        },
        updateCouponList(card) {
            if (this.couponListTemp && this.couponListTemp.length > 0) {
                let index = this.couponListTemp.map(item => item.cardno).indexOf(card.cardno);
                // this.couponListTemp.forEach((item, idx) => {
                //     this.$set(this.couponListTemp, idx, { ...item, checked: false });
                // });
                this.$set(this.couponListTemp, index, { ...card, checked: card.checked });
                let selected = this.couponListTemp.filter((item) => item.checked).map((item) => item.cardno);
                this.$emit('update', selected)
            }
        },
        updateExchangeCardList(card) {
            if (this.exchangeCardListTemp && this.exchangeCardListTemp.length > 0) {
                let selected = this.exchangeCardListTemp.filter(item => item.checked).map(item => item.cardno);
                if (selected.length > this.maxSelected) {
                    let index = this.exchangeCardListTemp.map(item => item.cardno).indexOf(card.cardno);
                    this.$set(this.exchangeCardListTemp, index, { ...card, checked: false });
                    this.$toast('使用兑换券的数量不能超过座位数');
                } else {
                    this.$emit('update', selected)
                }
            }
        },
        generateTemp(list) {
            if (list && list.length > 0) {
                let listTemp = list.map((item) => {
                    return {
                        ...item,
                        checked: this.selected.indexOf(item.cardno) > -1 ? true : false
                    }
                })
                return listTemp;
            }
            return [];
        }
    },
    components: {
        UserCard,
        UserCardDialog
    }
}
</script>

<style lang="scss" scoped>
.component-wrap {
	background-color: $bg-light-color;
	/deep/ .van-tabs {
		height: 100%;
		.van-tabs__content {
			flex: 1;
			display: flex;
			flex-direction: column;
			.van-tab__pane {
				flex: 1;
				position: relative;

				.movie_tab-wrap {
					padding: 0 7px;
				}
			}
		}
	}
}
.coupon-actions {
	background-color: #fff;
	padding: 10px 10px;
	.coupon_confirm-btn-wrap {
		width: 100%;
		background-color: $primary-color;
		padding: 15px 0;
		border-radius: 35px;
		color: #fff;
		> div {
			padding: 0 10px;
			font-size: 16px;
		}
		.coupon_confirm-tips {
			padding-left: 20px;
			width: 80%;
		}
		.coupon_confirm {
			border-left: 1px solid #fff;
			width: 40%;
		}
	}
}
</style>
